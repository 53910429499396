import React from 'react'
import Button from '@mui/material/Button'
import { Link } from 'react-router-dom'

export default function About() {
	return (
		<div className='About' id='about'>
			<div className='home-container'>
				<img className='picture' alt='bio pic' src='/images/profile.jpg'></img>
				<div className='about-container'>
					<h1 className='hook'>Hi, I'm Cole</h1>
					<p className='about'>
						I'm a software developer with a passion for coding,
						UI/UX development, A.I., sports & gaming. I
						graduated from <Link to='https://www.jmu.edu/' target='_blank'>James Madison University</Link> with a B.S. in Computer
						Science. I'm always on the hunt for a new challenge; feel free to look around at my
						<a href='#skills'> skills</a> & 
						<a href='#experience'> experience</a>!
						{/* <a href='#portfolio'> portfolio</a>, or contact me directly! */}
					</p>
					<div className='cta-buttons'>
						<Button
							className='cta mr-10'
							size='large'
							variant='outlined'
							href='mailto:coledickerson.dev@gmail.com'
						>
							<img
								className='email-icon'
								alt='email icon'
								src='/images/logos/email.svg'
							/>
							<span className='email-cta'>Contact</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}
